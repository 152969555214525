import App from './App.svelte';
// App
const app = new App({
    target: document.body,
    props: {
        name: 'Simone Anile'
    }
});
// Theme
function setTheme(name, themeColor, save) {
    document.querySelector('[name=theme-color]').setAttribute("content", themeColor);
    if (save) {
        localStorage.theme = name;
    }
}
function setDarkTheme(save) {
    let name = "dark";
    let themeColor = "rgba(31, 41, 55, 1)";
    document.documentElement.classList.add(name);
    setTheme(name, themeColor, save);
}
function setLightTheme(save) {
    let name = "light";
    let darkThemeName = "dark";
    let themeColor = "rgba(255, 255, 255, 1)";
    document.documentElement.classList.remove(darkThemeName);
    setTheme(name, themeColor, save);
}
function removeSavedTheme() {
    // Whenever the user explicitly chooses to respect the OS preference
    localStorage.removeItem('theme');
}
// On page load or when changing themes, best to add inline in `head` to avoid FOUC
if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
    setDarkTheme(false);
}
else {
    setLightTheme(false);
}
const themeButton = document.querySelector('#theme-button'); // Light Icon  
themeButton.addEventListener('click', () => {
    if (document.documentElement.classList.contains('dark')) {
        setLightTheme(true);
    }
    else {
        setDarkTheme(true);
    }
});
// Menu
const button = document.querySelector('#menu-button'); // Hamburger Icon
const menu = document.querySelector('#menu'); // Menu
button.addEventListener('click', () => {
    menu.classList.toggle('hidden');
});
// Service worker
if ("serviceWorker" in navigator) {
    window.addEventListener("load", () => {
        navigator.serviceWorker.register("/service-worker.js").then(async (reg) => {
            console.log("Service worker registered.", reg);
            //const registration = await navigator.serviceWorker.ready;
            const registration = reg;
            if ('periodicSync' in registration) {
                try {
                    await registration.periodicSync.register('content-sync', {
                        //// An interval of one day.
                        //minInterval: 24 * 60 * 60 * 1000,
                        minInterval: 30 * 1000,
                    });
                }
                catch (error) {
                    // Periodic background sync cannot be used.
                    let p = document.createElement("p");
                    document.getElementsByTagName("main")[0].appendChild(p);
                }
            }
        });
    });
}
// TEST
// Test this by running the code snippet below and then
// use the "Offline" checkbox in DevTools Network panel
window.addEventListener('online', handleConnection);
window.addEventListener('offline', handleConnection);
function handleConnection() {
    if (navigator.onLine) {
        isReachable(getServerUrl()).then(function (online) {
            if (online) {
                // handle online status
                console.log('online');
                let p = document.createElement("p");
                p.innerHTML = "Online: " + new Date().toLocaleTimeString();
                document.getElementsByTagName("main")[0].appendChild(p);
                document.getElementsByTagName("main")[0].appendChild(document.createElement("br"));
            }
            else {
                console.log('no connectivity');
                let p = document.createElement("p");
                p.innerHTML = "no connectivity: " + new Date().toLocaleTimeString();
                document.getElementsByTagName("main")[0].appendChild(p);
                document.getElementsByTagName("main")[0].appendChild(document.createElement("br"));
            }
        });
    }
    else {
        // handle offline status
        console.log('offline');
        let p = document.createElement("p");
        p.innerHTML = "Offline: " + new Date().toLocaleTimeString();
        document.getElementsByTagName("main")[0].appendChild(p);
        document.getElementsByTagName("main")[0].appendChild(document.createElement("br"));
    }
}
function isReachable(url) {
    /**
     * Note: fetch() still "succeeds" for 404s on subdirectories,
     * which is ok when only testing for domain reachability.
     *
     * Example:
     *   https://google.com/noexist does not throw
     *   https://noexist.com/noexist does throw
     */
    return fetch(url, { method: 'HEAD', mode: 'no-cors' })
        .then(function (resp) {
        return resp && (resp.ok || resp.type === 'opaque');
    })
        .catch(function (err) {
        console.warn('[conn test failure]:', err);
    });
}
function getServerUrl() {
    return window.location.origin;
}
// Export
export default app;
